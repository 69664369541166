<template>
  <b-row>
    <b-col md="12">
      <b-card no-body>
        <b-card-body>
          <div class="d-flex justify-content-between">
            <b-button
              variant="danger"
              @click="show = !show"
            >
              SMS Metnini Güncelle
            </b-button>
            <b-button
              v-b-modal.modal-center
              variant="primary"
              :disabled="waitCount === 0"
            >
              Toplu SMS Gönder
            </b-button>
          </div>
          <div
            v-if="show"
            class="mt-2 mb-2"
          >
            <b-alert
              variant="primary"
              show
            >
              <div class="alert-body">
                <span>Son SMS metin güncellemesi {{ smsText.username }} tarafından {{ moment(smsText.modified).format('LLL') }} tarihinde yapıldı.</span>
              </div>
            </b-alert>
            <b-alert
              variant="warning"
              show
            >
              <div class="alert-body">
                <div>Müşteri Adı Soyadını eklemek istediğiniz yere <span class="text-success">{adisoyadi}</span> etiketini yazınız.</div>
                <div>Servis indirim kodu eklemek istediğiniz yere <span class="text-success">{indirim_kodu}</span> etiketini yazınız.</div>
              </div>
            </b-alert>
            <b-form-group
              label="SMS Metni"
              label-for="sms_text"
            >
              <b-form-textarea
                id="sms_text"
                placeholder="SMS Metni"
                :value="smsText.message"
                rows="8"
              />
            </b-form-group>
            <b-button
              variant="success"
              @click="saveSmsText"
            >
              Güncelle
            </b-button>
          </div>
        </b-card-body>
        <b-table
          responsive="sm"
          :fields="fields"
          :items="dataList"
          striped
        >
          <template #cell(birthday)="data">
            {{ moment(data.item.birthday).format('LL') }}
          </template>
          <template #cell(control)="data">
            <b-badge
              v-if="data.item.control"
              variant="success"
            >
              Tamamlandı
            </b-badge>
            <b-badge
              v-else
              variant="danger"
            >
              Beklemede
            </b-badge>
          </template>
        </b-table>
      </b-card>
    </b-col>
    <b-modal
      id="modal-center"
      centered
      title="Toplu SMS Gönder"
      hide-footer
    >
      <b-alert
        v-if="sending"
        show
        variant="primary"
      >
        <div class="alert-body text-center">
          <pre-loading />
        </div>
      </b-alert>
      <div v-else>
        <div class="mb-2 text-center">
          Toplam <strong class="text-danger">{{ waitCount }}</strong> kişiye SMS gönderimi yapılacak.
        </div>
        <b-button
          variant="success"
          block
          @click="batchSendSms"
        >
          Gönder
        </b-button>
      </div>
    </b-modal>
  </b-row>
</template>
<script>
import {
  BCard, BCardBody, BRow, BCol, BTable, BButton, BBadge, BFormGroup, BFormTextarea, BAlert, BModal,
} from 'bootstrap-vue'
import PreLoading from '@/layouts/components/common/PreLoading.vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BCardBody,
    BTable,
    BRow,
    BCol,
    BButton,
    BBadge,
    BFormTextarea,
    BFormGroup,
    BAlert,
    BModal,
    PreLoading,
  },
  data() {
    return {
      waitCount: 0,
      show: false,
      fields: [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'customer',
          label: 'Adı Soyadı',
        },
        {
          key: 'phone',
          label: 'Telefon',
        },
        {
          key: 'birthday',
          label: 'Doğum Tarihi',
        },
        {
          key: 'control',
          label: 'Durum',
        },
      ],
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['birthdaySms/getDataList']
    },
    smsText() {
      return this.$store.getters['birthdaySms/getSmsText']
    },
    saveStatus() {
      return this.$store.getters['birthdaySms/dataSaveStatus']
    },
    sending() {
      return this.$store.getters['birthdaySms/sending']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
        this.getSmsText()
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
    },
  },
  created() {
    this.getDataList()
    this.getSmsText()
  },
  methods: {
    calcWaits() {
      const data = this.dataList
      // eslint-disable-next-line array-callback-return
      data.find(value => {
        if (!value.control) {
          this.waitCount += 1
        }
      })
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    getDataList() {
      this.$store.dispatch('birthdaySms/dataList')
        .then(res => {
          if (res.length > 0) {
            this.calcWaits()
          }
        })
    },
    getSmsText() {
      this.$store.dispatch('birthdaySms/smsText')
    },
    saveSmsText() {
      this.$store.dispatch('birthdaySms/saveData', this.smsText)
    },
    batchSendSms() {
      this.$store.dispatch('birthdaySms/batchSendSms', this.dataList)
        .then(res => {
          if (res) {
            this.getDataList()
          }
        })
    },
  },
}
</script>
